<!--
 * @Author: 钟澳鹏 1269372093@qq.com
 * @Date: 2023-05-17 12:08:30
 * @LastEditors: 钟澳鹏 1269372093@qq.com
 * @LastEditTime: 2023-09-15 08:55:25
 * @FilePath: \dalian\src\components\TabContent2.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="tab-content2">
    <img src="../assets/banner3.png" class="banner">
    <div class="content">
      <GridTitle text="企业简介"></GridTitle>
      <div class="grid-1">
        <img src="../assets/tab4/1.png">
        <div>湖南商旗信息技术有限公司是优秀的供应链管理解决方案服务商，公司致力于搭建商流，物流，资金流，信息流、技术流五流合一的供应链服务平台。公司自成立以来一直聚焦大宗商品产业领域，整合各流通要素，为生产制造业企业提供包括采购分销、门到门全程物流、库存管理、供应链金融、剪切加工、信息咨询等在内的全价值链流通服务，满足其“稳定货源、组合供应、快速分销、降低成本、控制风险、综合服务”等核心诉求。</div>
        <div>公司构建了“网络化物流服务体系、智能化信息科技体系、全面风险管控体系”，对商品进行了多维度的组合，客户结构实现全面优化。</div>
        <div>公司以供应链客户为基础，依托专业供应链服务，将产供销环节化繁为简，让企业专注研发和运营，提升核心竞争力。</div>
      </div>
    </div>
  </div>
</template>

<script>
import GridTitle from './GridTitle.vue'
export default {
  name: 'TabContent4',
  components:{
    GridTitle
  }
}
</script>

<style scoped>
.banner{
  height: 651px;
  width: 100%;
 }
 .content{
  width: 1200px;
  margin: 72px auto 0 auto;
 }

 .grid-1{
    font-size: 22px;
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #666666;
    line-height: 32px;
    padding: 67px 0 57px 0;
    text-align: justify;
 }
 .grid-1>div{
    padding: 19px 0;
 }
 .grid-1>img{
    height: 555px;
    width: 1015px;
    margin: 0 auto 40px auto;
    display: block;
 }
</style>
